import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

import { decrypt, encrypt } from './assets/utils/encrypt';

const app = createApp(App);

app.config.globalProperties.$decrypt = decrypt;
app.config.globalProperties.$encrypt = encrypt;

app.use(store).use(router).mount('#app');
