import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/',
    name: 'demo',
    component: () => import('../views/Demo.vue'),
  },
  {
    path: '/doc',
    name: 'doc',
    component: () => import('../views/demoPages/richText.vue'),
  },
  {
    path: '/doc_coop',
    name: 'doc_coop',
    component: () => import('../views/demoPages/richTextCol.vue'),
  },
  {
    path: '/doc2',
    name: 'doc2',
    component: () => import('../views/default.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
