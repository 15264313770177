<template>
  <router-view v-slot="{ Component }">
    <transition :name="transMode">
      <component :is="Component" />
    </transition>
  </router-view>
</template>
<script lang="ts" setup>
import { useRouter } from 'vue-router';
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import '@/assets/fonts.css';

const store = useStore();

const transMode = ref('');

const appInit = () => {
  const theme = localStorage.getItem('theme');

  if (localStorage.getItem('token') !== null) {
    store.commit('setToken', localStorage.getItem('token'));
  }

  if (localStorage.getItem('userData') !== null) {
    store.commit('setUserData', JSON.parse(localStorage.getItem('userData')!));
  }
};

appInit();
</script>
<style lang="scss">
* {
  -webkit-touch-callout: none;
  /*系统默认菜单被禁用*/
  -webkit-user-select: none;
  /*webkit浏览器*/
  -khtml-user-select: none;
  /*早期浏览器*/
  -moz-user-select: none;
  /*火狐*/
  -ms-user-select: none;
  /*IE10*/
  user-select: none;
}

html,
body,
#app {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  min-width: 750px;
  min-height: 80px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Edge */
  scrollbar-width: none;

  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
    /* WebKit */
  }

  i,
  div,
  input,
  p {
    font-family: 'CANGERYUYANG';
  }

  router-view {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
}

/*全局滚动条样式*/
::-webkit-scrollbar {
  position: fixed;
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #6363631d;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #696969c8;
}

::-webkit-scrollbar-button {
  height: 30px;
}

/*全局滚动条样式结束*/
</style>
