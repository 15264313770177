import { createStore } from 'vuex';

export default createStore({
  state: {
    appData: {
      token: '',
      theme: 'light',
      menuID: 0,
    },
    userData: {
      user_name: '',
      user_id: '',
    },
  },
  getters: {
  },
  mutations: {
    setToken(state, data) {
      state.appData.token = data;
      localStorage.token = data;
    },
    setTheme(state, data) {
      state.appData.theme = this.encrypt(data);
      localStorage.theme = this.encrypt(data);
    },
    setMenuID(state, data) {
      state.appData.menuID = this.encrypt(data);
      localStorage.menuID = this.encrypt(data);
    },

    setUserData(state, data) {
      state.userData = data;
      localStorage.userData = JSON.stringify(data);
    },
  },
  actions: {
  },
  modules: {
  },
});
